export default {
  individual: {
    documents: {
        blank_doc: true,
        fromTemplate: false,
        googleDrive: false,
        dropBox: false,
        oneDrive: false,
        sharePoint: false,
        localFile: true,
        rotatePage: false,
        add_remove_page: false,
        correct_document: false,
        settings: false,
        edit_pdf:false,
        essentialFields: {
          signature: true,
          initial: true,
          fullName: true,
          dateSigned: true,
          company: true,
          title: true,
          singleLineText: true,
          checkbox: true,
          address: true,
          
        },
        entityFields: false,
        contentFields: {
          mySignature: false,
          heading: false,
          paragraph: false,
          image: false,
          myInitial: false,
          horizontalLine: false,
          //Template Issue
          singleLineContentEle:false,
          zseal:false,
        },
        fillableFields: {
          requestDoc: false,
          date: true,
          select: false,
          number: false,
          yerOrNo: false,
          multiLineText: true,
          multiSelect: false,
          formula: false,
          currency: false,
          dateTime: false,
          dateTimeRange: false,
          dateRange: false,
          time: false,
          list: false,
          timeRange: false,
          fixedTime: false,
          weekDays: false,
          
          //template fields
          dataTable:false
        },
        otherPrimaryFields: {
          firstName: true,
          lastName: true,
          phoneNo: true,
          emailId: true,
        },
        getLink:false,
        editTemplate:true,
        sendToContact:true,
        bulkSend:false,
        printCheque:false,
        shareWithUsers:false,
        changeOwner:false,
        viewData:true,
        duplicateTemplate:false,
        renameTemplate:true,
        inActiveTemplate:false,
        deleteTemplate:true,
        importContacts:false,
        exportContacts:false,
    },
  },
  business: {
    documents: {
        blank_doc: true,
        fromTemplate: true,
        googleDrive: true,
        dropBox: true,
        oneDrive: true,
        sharePoint: true,
        localFile: true,
        rotatePage: true,
        add_remove_page: true,
        correct_document: true,
        settings: true,
        edit_pdf:true,
        essentialFields: {
          signature: true,
          initial: true,
          fullName: true,
          dateSigned: true,
          company: true,
          title: true,
          singleLineText: true,
          checkbox: true,
          address: true,
        },
        entityFields: true,
        contentFields: {
          mySignature: true,
          heading: true,
          paragraph: true,
          image: true,
          myInitial: true,
          horizontalLine: true,
          //Template Fields
          singleLineContentEle:false,
          zseal:false,
        },
        fillableFields: {
          requestDoc: true,
          date: true,
          select: true,
          number: true,
          yerOrNo: true,
          multiLineText: true,
          multiSelect: true,
          formula: true,
          currency: true,
          dateTime: true,
          dateTimeRange: true,
          dateRange: true,
          time: true,
          list: true,
          timeRange: true,
          fixedTime: true,
          weekDays: true,
          //template fields
          dataTable:true
        },
        otherPrimaryFields: {
          firstName: true,
          lastName: true,
          phoneNo: true,
          emailId: true,
        },
        getLink:true,
        editTemplate:true,
        sendToContact:true,
        bulkSend:true,
        printCheque:true,
        shareWithUsers:true,
        changeOwner:true,
        viewData:true,
        duplicateTemplate:true,
        renameTemplate:true,
        inActiveTemplate:true,
        deleteTemplate:true,
        importContacts:true,
        exportContacts:true,
    },
  },
}
