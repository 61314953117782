import Features from "../features/options.js";
import {
    mapGetters
} from 'vuex';

export default {
    data() {
        return {}
    },
    computed: {
        ...mapGetters("companyDocuments", ["getCompanyAllDocuments"]),

        ...mapGetters("documentCategories", ["getAllCategories"]),
        ...mapGetters("documentGroups", ["getAllGroups"]),
        ...mapGetters("auth", [
            "getAuthenticatedUser",
            "getInitialUrl",
            "getUserSignature",
            "getDefaultDateFormat",
            "getActiveWorkspace",
            "getUserType", "getPermissionList", "getWorkspaceList", "getCompanyPermissionSet"
        ]),
        ...mapGetters("paymentGateway", ["getPaymentsHistory", "getPlansFeatures"]),
    },
    methods: {

        getAccess(typo='document',accessKey,isField='')
        {
    
          let planName= this.getActiveWorkspace &&this.getActiveWorkspace.plan_type;
            let plan= planName ? planName.toLowerCase() : '' ;
            if(Features && Features[plan] &&Features[plan][typo]) 
            {
              if(isField && Features[plan][typo][isField] && isField && Features[plan][typo][isField][accessKey])
              {
                // console.log("true Field Access",Features[plan][typo][accessKey]);
                return Features[plan][typo][isField][accessKey]
              }
              // console.log("true access",Features[plan][typo][accessKey])
              return Features[plan][typo][accessKey];
            }
            else{
              return false
            }
    
        },
        getAccessOfFields(typo='document',isField='')
        {
          let planName= this.getActiveWorkspace &&this.getActiveWorkspace.plan_type;
            let plan= planName ? planName.toLowerCase() : '' ;
            if(Features[plan] &&Features[plan][typo] && Features[plan][typo][isField])
            {
          let jsonObject= Features[plan][typo][isField];
            const isAnyKeyTrue = Object.values(jsonObject).some(value => value === true);
            return isAnyKeyTrue;
            }
            else{
              return false;
            }
        }
    },
}